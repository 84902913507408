<template>
	<div class="operation_record">
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="operationRecordTableConf" v-on:resetSearch="resetSearch" v-on:searchParamsChange="searchParamsChange" :total="total" :currentPage="currentPage" :condition="form" :showOperation="true" :dataOrigin="dataOrigin">
			<template #pict_url="scope">
				<el-popover
					trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #operation="scope">
				<div @click="detail(scope.rowData.id)" class="cur_p">查看详情</div>
			</template>
		</e-table>
		<e-dialog title="操作日志" ref="dialogRef" v-on:confirm="confirm">
			<el-timeline>
				<el-timeline-item v-for="con in console" :key="con.index">
					<div class="pl20 pt20 pr20 pb20 box_s">
						<div>操作：{{con.statusInfo}}</div>
						<div class="pt4 pb4" v-if="con.memo">备注：{{con.memo}}-</div>
						<div class="pt4 pb4" v-else>备注：-</div>
						<div>{{con.username}} 提交于{{con.createtime}}</div>
					</div>
				</el-timeline-item>
			</el-timeline>
		</e-dialog>
	</div>
</template>

<script>
import {operationRecordTableConf} from '@data/operationRecord'
export default {
	name: 'operationRecord',
	data() {
		return {
			operationRecordTableConf,
			dataOrigin: {
				data: []
			},
			total: 1,
			currentPage: 1,
			form: {},
			id: "",
			condition: {},
			console: []
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetLists()
		},
		confirm(){
			this.$refs.dialogRef.handleClose()
		},
		detail(id){
			let that = this
			// console.log(that.dataOrigin)
			that.console = []
			this.get('?server=live.console.log.by.id.get',{id:id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data)
				that.console = response.data
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
			this.$refs.dialogRef.handleOpen()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetLists()
		},
		handleClick() {},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			that.$refs.tables && that.$refs.tables.initData()
			// console.log(that.dataOrigin)
			this.get('?server=live.console.log.lists.get',{
				page:this.currentPage,
				query:this.condition.title,
				shop_name:this.condition.nick,
				type:this.condition.statusInfo}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.GetLists()
	}
}
</script>