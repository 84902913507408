export const operationRecordTableConf = [
	{
		label: '商品主图',
		prop: 'pict_url',
	},
	{
		label: '商品名称',
		prop: 'title',
		search: true,
		type: 'input'
	},
	{
		label: '店铺名称',
		prop: 'nick',
		search: true,
		type: 'input'
	},
	{
		label: '审核状态',
		prop: 'statusInfo',
		search: true,
		type: 'select',
		options: [
			{
				label: '待寄样',
				value: 1
			},
			{
				label: '审核驳回',
				value: 2
			},
			{
				label: '入库',
				value: 4
			},
			{
				label: '已排期',
				value: 5
			},
			{
				label: '审核驳回',
				value: 6
			},
			{
				label: '待完成',
				value: 7
			},
			{
				label: '增播',
				value: 9
			}
		]
	},
	{
		label: '排期日期',
		prop: 'zbtime'
	},
	{
		label: '审核备注',
		prop: 'note'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 90
	}
]